<template>
  <div>
    <section v-if="!loaded">
      <article class="media">
        <figure class="media-left">
          <p class="image is-64x64">
            <b-skeleton circle width="64px" height="64px"></b-skeleton>
          </p>
        </figure>
        <div class="media-content">
          <div class="content">
            <p>
              <b-skeleton active></b-skeleton>
              <b-skeleton height="80px"></b-skeleton>
            </p>
          </div>
          <nav class="level is-mobile">
            <div class="level-left">
              <a class="level-item">
                <span class="icon is-small">
                  <b-skeleton></b-skeleton>
                </span>
              </a>
              <a class="level-item">
                <span class="icon is-small">
                  <b-skeleton></b-skeleton>
                </span>
              </a>
            </div>
          </nav>
        </div>
      </article>
    </section>

    <div v-else v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: "ApplicationTemplate",
  data() {
    return {
      content: "",
      loaded: false,
    };
  },
  props: {
    template: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    this.loaded = false;
    await this.$store.dispatch("template/fetchTemplate", this.template);
    this.content = this.$store.getters["template/get"](this.template);
    this.loaded = true;
  },
};
</script>

<style scoped lang="scss"></style>
