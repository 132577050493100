<template>
  <div>
    <!--
    <div>
      {{ fieldData.label }}
    </div>
    <div>
      <div class="kyc-description" v-html="fieldData.content" />
    </div>
    -->
    <template
      v-if="status === KYC_STATUSES.NOT_STARTED || forcedRetry || justStarted"
    >
      <div
        v-if="introScreen === 'PREP'"
        class="kyc-card kyc-prep is-flex-direction-column"
      >
        <application-template
          v-if="!isMortgage"
          template="KYC_PREP"
        ></application-template>
        <application-template
          v-else
          template="KYC_PREP_MORTGAGE"
        ></application-template>
        <b-button
          @click="introScreen = 'CHOOSE'"
          class="mt-5"
          id="kycPrepared"
          >{{
            isMortgage
              ? $t("mortgage:application.kyc.prep.button")
              : $t("af:application.kyc.prep.button")
          }}</b-button
        >
      </div>
      <div v-else-if="introScreen === 'CHOOSE'">
        <div v-if="openings.isOpen">
          <div class="opened-bubble is-open">
            <b-icon icon="check"> </b-icon>
            <div v-if="kycData.kycType === 'RTI'">
              <span>{{
                isMortgage
                  ? $t("mortgage:application.kyc.label.operator_available_rti")
                  : $t("af:application.kyc.label.operator_available_rti")
              }}</span
              >&nbsp;
              <span class="has-text-weight-normal" v-if="openings.openUntil"
                >{{
                  isMortgage
                    ? $t(
                        "mortgage:application.kyc.label.operator_available_until"
                      )
                    : $t("af:application.kyc.label.operator_available_until")
                }}
                {{ formatTimeFromUnix(openings.openUntil) }}</span
              >
            </div>
            <div v-if="kycData.kycType === 'NRTI'">
              <span>{{
                isMortgage
                  ? $t("mortgage:application.kyc.label.operator_available_nrti")
                  : $t("af:application.kyc.label.operator_available_nrti")
              }}</span
              >&nbsp;
            </div>
            <div v-if="kycData.kycType === 'DEFERRED'">
              <span>{{
                isMortgage
                  ? $t(
                      "mortgage:application.kyc.label.operator_available_deferred"
                    )
                  : $t("af:application.kyc.label.operator_available_deferred")
              }}</span
              >&nbsp;
              <span class="has-text-weight-normal"
                >{{
                  isMortgage
                    ? $t(
                        "mortgage:application.kyc.label.operator_available_deferred"
                      )
                    : $t("af:application.kyc.label.operator_available_deferred")
                }}
                {{ formatTimeFromUnix(openings.openUntil) }}</span
              >
            </div>
          </div>
          <div class="has-text-weight-bold" :style="{ padding: '32px' }">
            {{
              isMortgage
                ? $t("mortgage:application.kyc.label.choose_device")
                : $t("af:application.kyc.label.choose_device")
            }}
          </div>
          <div class="devices">
            <div class="device">
              <div
                id="kycOnDesktop"
                class="device__tile"
                :class="{ 'disabled-tile': waitingForResponse }"
                @click="goDesktop"
              >
                <b-icon
                  v-if="!(waitingForResponse && selectedMode === 'desktop')"
                  icon-pack="fa"
                  icon="desktop"
                  type="is-grey-icon"
                  size="is-large"
                ></b-icon>
                <b-icon
                  v-else
                  icon="spinner"
                  size="is-large"
                  class="spinner-rotate"
                ></b-icon>

                <p class="has-text-centered has-text-weight-bold mb-0">
                  {{
                    isMortgage
                      ? $t("mortgage:application.video_call.label.this_device")
                      : $t("af:application.video_call.label.this_device")
                  }}
                </p>

                <p class="has-text-centered is-size-7">
                  {{
                    isMortgage
                      ? $t(
                          "mortgage:application.video_call.label.this_device.footer"
                        )
                      : $t("af:application.video_call.label.this_device.footer")
                  }}
                </p>
              </div>
            </div>
            <div class="device">
              <div
                id="kycOnMobile"
                class="device__tile"
                :class="{ 'disabled-tile': waitingForResponse }"
                @click="goMobile"
              >
                <b-icon
                  v-if="!(waitingForResponse && selectedMode === 'mobile')"
                  icon-pack="fa"
                  icon="mobile"
                  type="is-grey-icon"
                  size="is-large"
                ></b-icon>
                <b-icon
                  v-else
                  icon="spinner"
                  size="is-large"
                  class="spinner-rotate"
                ></b-icon>

                <p class="has-text-centered has-text-weight-bold mb-0">
                  {{
                    isMortgage
                      ? $t("mortgage:application.video_call.label.mobil")
                      : $t("af:application.video_call.label.mobil")
                  }}
                </p>

                <p class="has-text-centered is-size-7">
                  {{
                    isMortgage
                      ? $t("mortgage:application.video_call.label.mobil.footer")
                      : $t("af:application.video_call.label.mobil.footer")
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="opened-bubble is-closed">
            <b-icon icon="info-circle"> </b-icon>
            <div>
              {{
                isMortgage
                  ? $t("mortgage:application.kyc.label.operator_not_available")
                  : $t("af:application.kyc.label.operator_not_available")
              }}
            </div>
          </div>
          <div class="has-text-weight-bold" :style="{ padding: '32px' }">
            {{
              isMortgage
                ? $t("mortgage:application.kyc.label.come_back_later")
                : $t("af:application.kyc.label.come_back_later")
            }}
          </div>
          <div class="opening-table">
            <template v-for="(dayData, index) in openings.openings">
              <div class="has-text-weight-bold" :key="'openingInfoDay' + index">
                {{ $t("af:dayOfWeek_" + (index + new Date().getDay())) }}
              </div>
              <div :key="'openingInfoTime' + index">
                {{ formatTimeFromUnix(dayData.open) }} -
                {{ formatTimeFromUnix(dayData.close) }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>

    <template v-if="status === KYC_STATUSES.CREATED">
      <div class="pending-info mt-5">
        <b-icon
          icon-pack="fa"
          icon="info-circle"
          size="is-medium"
          type="is-blue"
          class="mr-2"
        ></b-icon>
        <span class="has-text-grey-icon">{{
          isMortgage
            ? $t("mortgage:flow_kyc.field.created.info")
            : $t("af:flow_kyc.field.created.info")
        }}</span>
      </div>
    </template>

    <template v-if="status === KYC_STATUSES.STARTED && !justStarted">
      <div v-if="url" class="kyc-card is-flex-direction-column">
        <p class="read-code-title has-text-weight-bold mb-0">
          {{
            isMortgage
              ? $t("mortgage:application.kyc.read_code")
              : $t("af:application.kyc.read_code")
          }}
        </p>
        <p class="mb-0 has-text-centered">
          <qrcode-vue
            v-if="url && (selectedMode === null || selectedMode === 'mobile')"
            :value="url"
            :size="200"
            level="M"
            class="qrcode mb-2"
          />
        </p>
        <p class="is-size-6 has-text-grey mb-0">
          {{
            isMortgage
              ? $t("mortgage:application.kyc.read_code.help_1")
              : $t("af:application.kyc.read_code.help_1")
          }}
        </p>
        <p class="is-size-6 has-text-grey mb-0">
          {{
            isMortgage
              ? $t("mortgage:application.kyc.read_code.help_2")
              : $t("af:application.kyc.read_code.help_2")
          }}
        </p>
        <div class="is-flex is-justify-content-center">
          <a
            v-if="url && (selectedMode === null || selectedMode === 'mobile')"
            :href="url"
            class="button is-text has-text-weight-bold"
            id="kycOpenLink"
            >{{
              isMortgage
                ? $t("mortgage:application.kyc.field.click_to_open_room.label")
                : $t("af:application.kyc.field.click_to_open_room.label")
            }}</a
          >
        </div>
      </div>
      <div v-if="url" class="pending-info mt-5">
        <b-icon
          icon-pack="fa"
          icon="info-circle"
          size="is-medium"
          type="is-blue"
          class="mr-2"
        ></b-icon>
        <span class="has-text-grey-icon">{{
          isMortgage
            ? $t("mortgage:flow_kyc.field.this_device.info")
            : $t("af:flow_kyc.field.this_device.info")
        }}</span>
      </div>
    </template>

    <template v-if="status === KYC_STATUSES.PENDING">
      <div class="pending-info mt-5">
        <b-icon
          icon-pack="fa"
          icon="info-circle"
          size="is-medium"
          type="is-blue"
          class="mr-2"
        ></b-icon>
        <span class="has-text-grey-icon">{{
          isMortgage
            ? $t("mortgage:flow_kyc.field.pending.info")
            : $t("af:flow_kyc.field.pending.info")
        }}</span>
      </div>
    </template>

    <template v-if="status === KYC_STATUSES.REVIEWING">
      <div class="pending-info mt-5">
        <b-icon
          icon-pack="fa"
          icon="info-circle"
          size="is-medium"
          type="is-blue"
          class="mr-2"
        ></b-icon>
        <span class="has-text-grey-icon">{{
          isMortgage
            ? $t("mortgage:flow_kyc.field.reviewing.info")
            : $t("af:flow_kyc.field.reviewing.info")
        }}</span>
      </div>
    </template>

    <template v-if="status === KYC_STATUSES.WAITING_FOR_4_EYES">
      <div class="kyc-card">
        <div class="kyc-icon-holder">
          <div class="kyc-status-icon">
            <b-icon :style="{ color: '#616161' }" :icon="statusIcon" />
          </div>
        </div>
        <div>
          <div class="kyc-label">
            {{
              isMortgage
                ? $t(`mortgage:flow.kyc_field.info.title.waiting_for_4ec`)
                : $t(`af:flow.kyc_field.info.title.waiting_for_4ec`)
            }}
          </div>
          <div class="kyc-description">
            {{
              isMortgage
                ? $t(`mortgage:flow.kyc_field.info.description.waiting_for_4ec`)
                : $t(`af:flow.kyc_field.info.description.waiting_for_4ec`)
            }}
          </div>
        </div>
      </div>

      <div class="identification-step">
        <div class="identification-step__number done">
          <b-icon icon="check" />
        </div>
        <div class="has-text-weight-bold">
          {{
            isMortgage
              ? $t("mortgage:flow.kyc_field.online_identification_success")
              : $t("af:flow.kyc_field.online_identification_success")
          }}
        </div>
      </div>

      <div class="identification-step">
        <div class="identification-step__number">2</div>
        <div class="has-text-weight-bold">
          {{
            isMortgage
              ? $t("mortgage:flow.kyc_field.check_identification_success")
              : $t("af:flow.kyc_field.check_identification_success")
          }}
        </div>
      </div>

      <div class="pending-info mt-5">
        <b-icon
          icon-pack="fa"
          icon="info-circle"
          size="is-medium"
          type="is-blue"
          class="mr-2"
        ></b-icon>
        <span class="has-text-grey-icon">{{
          isMortgage
            ? $t("mortgage:flow_kyc.field.waiting_for_4ec.info")
            : $t("af:flow_kyc.field.waiting_for_4ec.info")
        }}</span>
      </div>
    </template>

    <template v-if="status === KYC_STATUSES.FAILED && !forcedRetry">
      <div class="kyc-card">
        <div class="kyc-icon-holder">
          <div class="kyc-status-icon">
            <b-icon :style="{ color: '#616161' }" :icon="statusIcon" />
          </div>
        </div>
        <div>
          <div class="kyc-label">
            {{
              isMortgage
                ? $t(`mortgage:flow.kyc_field.info.title.failed`)
                : $t(`af:flow.kyc_field.info.title.failed`)
            }}
          </div>
          <div class="kyc-description">
            {{
              isMortgage
                ? $t(`mortgage:flow.kyc_field.info.description.failed`)
                : $t(`af:flow.kyc_field.info.description.failed`)
            }}

            <b-button @click="retry" class="mt-5" id="kycRetry">{{
              isMortgage
                ? $t(`mortgage:flow.kyc_field.button.retry`)
                : $t(`af:flow.kyc_field.button.retry`)
            }}</b-button>
          </div>
        </div>
      </div>
    </template>

    <template v-if="status === KYC_STATUSES.COMPLETED">
      <div class="kyc-card">
        <div class="kyc-icon-holder">
          <div class="kyc-status-icon">
            <b-icon :style="{ color: '#616161' }" :icon="statusIcon" />
          </div>
        </div>
        <div>
          <div class="kyc-label">
            {{
              isMortgage
                ? $t(`mortgage:flow.kyc_field.info.title.completed`)
                : $t(`af:flow.kyc_field.info.title.completed`)
            }}
          </div>
          <div class="kyc-description">
            {{
              isMortgage
                ? $t(`mortgage:flow.kyc_field.info.description.completed`)
                : $t(`af:flow.kyc_field.info.description.completed`)
            }}
          </div>
        </div>
      </div>
      <div class="identification-step">
        <div class="identification-step__number done">
          <b-icon icon="check" />
        </div>
        <div class="has-text-weight-bold">
          {{
            isMortgage
              ? $t("mortgage:flow.kyc_field.online_identification_success")
              : $t("af:flow.kyc_field.online_identification_success")
          }}
        </div>
      </div>

      <div class="identification-step">
        <div class="identification-step__number done">
          <b-icon icon="check" />
        </div>
        <div class="has-text-weight-bold">
          {{
            isMortgage
              ? $t("mortgage:flow.kyc_field.check_identification_success")
              : $t("af:flow.kyc_field.check_identification_success")
          }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { KYC_STATUSES } from "@/utils/const";
import ApplicationTemplate from "@/components/ApplicationTemplate.vue";
import QrcodeVue from "qrcode.vue";
import { formatTimeFromUnix } from "@/utils/util";
import i18n from "@/plugins/i18n";

export default {
  name: "KycField",
  components: { QrcodeVue, ApplicationTemplate },
  props: {
    fieldId: {
      type: Number,
      required: true,
    },
    questionId: {
      type: Number,
      required: true,
    },
    kycData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fieldData: {},
      selectedMode: null,
      introScreen: "PREP",
      openings: {},
      timer: null,
      forcedRetry: false,
      justStarted: false,
      waitingForResponse: false,
    };
  },
  computed: {
    KYC_STATUSES: () => KYC_STATUSES,
    statusIcon() {
      switch (this.status) {
        case KYC_STATUSES.NOT_STARTED:
          return "info";
        case KYC_STATUSES.CREATED:
          return "clock";
        case KYC_STATUSES.STARTED:
          return "camera";
        case KYC_STATUSES.PENDING:
          return "check";
        case KYC_STATUSES.REVIEWING:
          return "check";
        case KYC_STATUSES.WAITING_FOR_4_EYES:
          return "check";
        case KYC_STATUSES.COMPLETED:
          return "check";
        case KYC_STATUSES.FAILED:
          return "times";
        default:
          return "info";
      }
    },
    pollingNeeded() {
      return [
        KYC_STATUSES.CREATED,
        KYC_STATUSES.STARTED,
        KYC_STATUSES.WAITING_FOR_4_EYES,
        KYC_STATUSES.REVIEWING,
        KYC_STATUSES.PENDING,
      ].includes(this.status);
    },
    ...mapGetters({
      loanUrl: "loanApplication/kycUrl",
      loanStatus: "loanApplication/kycStatus",
      mortgageUrl: "mortgageApplication/kycUrl",
      mortgageStatus: "mortgageApplication/kycStatus",
    }),
    isMortgage() {
      return !!this.$route.meta.mortgage;
    },
    url() {
      return this.isMortgage ? this.mortgageUrl : this.loanUrl;
    },
    status() {
      return this.isMortgage ? this.mortgageStatus : this.loanStatus;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  async created() {
    await this.fetchData(this.fieldId);
    this.fieldData = this.$store.getters["specialFieldStore/get"](this.fieldId);
    if (this.isMortgage) {
      await this.$store.dispatch("mortgageApplication/fetchKycStatus");
    } else {
      await this.$store.dispatch("loanApplication/fetchKycStatus");
    }

    if (this.kycData.kycProvider === "FACEKOM") {
      if (this.isMortgage) {
        this.openings = await this.$store.dispatch(
          "mortgageApplication/fetchKycOpenings"
        );
      } else {
        this.openings = await this.$store.dispatch(
          "loanApplication/fetchKycOpenings"
        );
      }
    } else {
      this.openings.isOpen = true;
      this.openings.openUntil = null;
    }

    if (this.pollingNeeded) {
      this.startPolling();
    }
  },
  methods: {
    startPolling() {
      this.timer = setInterval(async () => {
        if (this.isMortgage) {
          await this.$store.dispatch("mortgageApplication/fetchKycStatus");
        } else {
          await this.$store.dispatch("loanApplication/fetchKycStatus");
        }
        if (!this.pollingNeeded) {
          clearInterval(this.timer);
        }
      }, 3000);
    },
    async start() {
      this.waitingForResponse = true;
      try {
        if (this.isMortgage) {
          await this.startMortgage();
        } else {
          await this.startLoan();
        }
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: i18n.t(
            this.isMortgage
              ? "mortgage:flow.kyc_field.failed_to_start"
              : "af:flow.kyc_field.failed_to_start"
          ),
          type: "is-danger",
        });
      }
      this.waitingForResponse = false;
      this.forcedRetry = false;
    },
    async goMobile() {
      this.selectedMode = "mobile";
      await this.start();
      this.startPolling();
    },
    async goDesktop() {
      this.justStarted = true;
      this.selectedMode = "desktop";
      await this.start();
      if (this.url) {
        window.location.href = this.url;
      }
    },
    retry() {
      this.introScreen = "CHOOSE";
      this.forcedRetry = true;
    },
    formatTimeFromUnix,
    ...mapActions({
      getMortgageStatus: "mortgageApplication/fetchKycStatus",
      getLoanStatus: "loanApplication/fetchKycStatus",
      startMortgage: "mortgageApplication/startKyc",
      startLoan: "loanApplication/startKyc",
      fetchData: "specialFieldStore/fetchOne",
    }),
  },
};
</script>

<style scoped lang="scss">
.kyc-card {
  background: white;
  border-radius: 32px;
  padding: 24px;
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.kyc-label {
  color: #424242;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 8px;
}

.kyc-description {
  color: #424242;
  font-size: 16pp;
}

.kyc-status-icon {
  font-size: 16px;
  display: flex;
  align-items: flex-start;
}

.kyc-icon-holder {
  align-self: flex-start;
  border-radius: 9999px;
  border: 2px solid #616161;
  padding: 6px;
}

.pending-info {
  display: flex;
  align-items: center;
}

.mbh {
  .pending-info {
    border-radius: 24px;
    padding: 16px 24px 16px 24px;
    background: #e6e9f0;

    .has-text-grey-icon {
      color: #203667 !important;
    }
  }
}

.kyc-prep::v-deep {
  .requirement {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0;

    &__icon {
      height: 40px;
      width: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 182, 127, 0.45);
    }
  }
}

.devices {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  .device {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: start;

    &__tile {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: white;
      border-radius: 32px;
      padding: 24px;
      cursor: pointer;
      margin-bottom: 1rem;
      gap: 16px;

      &.disabled-tile {
        background: #dddddd;
        opacity: 0.5;
        pointer-events: none;
      }

      .icon {
        font-size: 0.75rem;
        height: 1rem;
        width: 1rem;
      }
    }
  }
}

.opened-bubble {
  border-radius: 32px;
  padding: 24px 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;

  &.is-open {
    background-color: #c7e8ce;
    color: #17622d;
  }

  &.is-closed {
    background-color: #ffb3ce7d;
    color: #74003f;
  }
}

.opening-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-left: 32px;
}

.read-code-title {
  font-size: 24px;
}

.identification-step {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1.5rem 0;

  .identification-step__icon {
    height: 40px;
    width: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dfe6ef;
  }
}

.mbh {
  .identification-step__number {
    border-radius: 0;
    background-image: url("../assets/images/mbh_forma.svg");
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    font-weight: 700;
    color: #203667;
    font-size: 20px;
    margin-right: 8px;

    &.done {
      background-image: url("../assets/images/mbh_forma_dark.svg");
      color: white;
    }
  }

  .identification-step__icon {
    display: none;
  }
}

@media only screen and (min-width: 769px) {
  .kyc-status-icon {
    font-size: 64px;
  }

  .kyc-icon-holder {
    padding: 12px;
  }

  .kyc-card {
    padding: 32px;
    gap: 32px;
  }

  .devices {
    grid-template-columns: repeat(2, 1fr);
  }

  .read-code-title {
    font-size: 2rem;
  }

  .identification-step__number {
    margin-right: 16px !important;
  }
}

.spinner-rotate {
  animation: rotate 1.5s linear infinite;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
